import { createGlobalStyle } from "styled-components"
import "@fontsource/montserrat"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        text-decoration: none !important;
        font-family: "Montserrat";
        font-weight: 400;
        color: #5b5d5e;
        box-sizing: border-box;
    }

    html, body {
        overflow-x: hidden;
        min-height: 100%;
        scroll-padding-top: 90px;
    }

    .pt-20 {
        padding-top: 20px;
    }

    .pt-100 {
        padding-top: 100px;
    }

    .pt-50 {
        padding-top: 50px;

        @media only screen and (max-width: 768px) {
            padding-top: 25px;
        }
    }

    .pb-50 {
        padding-bottom: 50px;

        @media only screen and (max-width: 768px) {
            padding-bottom: 25px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
    }

    .with-margin {
        margin: 0 10%;

        @media only screen and (max-width: 768px) {
            margin 0 5%;
        }
    }

    h1, h2, h3, h4 {
        font-family: Montserrat;
    }

    h1 {
        color: #0073AF;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
    }

    h2, h3, h4 {
        color: #0073AF;
        font-weight: bold;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    p, li {
        color: #5b5d5e;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }
    
    body {
        background-color: #ffffff;
    }

    body p, h1 {
        margin-bottom: 0;
    }

    footer p {
    text-align: center;
    margin: 40px auto;

    .layout {
        max-width: 1300px;
        margin: 0 auto;
    }
}
`
