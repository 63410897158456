import React from "react"
import { GlobalStyle } from "../styles/GlobalStyles"
import SvgMap from "../components/SvgMap"

const Map = () => {
  return (
    <div>
      <GlobalStyle />
      <SvgMap />
    </div>
  )
}

export default Map
